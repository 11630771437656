.card_nfse {
  text-align: center;
  border: 1px solid #c0c0c0;
  border-radius: 15px;
  font-size: 18px;
  width: 80%;
  margin-left: 10%;

  p{
    margin: 0
  }
}