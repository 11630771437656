.tituloCampo{
  margin: 0px !important;
  text-align: center;
}

.separacaoParcelas{
  font-size: 25px !important;
  margin: 0px 5px 0px 5px !important;
}

.inputParcela{
  width: 50px !important;
  margin: 5px 0px !important;
}

.inputValor{
  width: 140px !important;
  margin: 5px 0px !important;
}

.inputVencimento{
  width: 180px !important;
  margin: 5px 0px !important;
}

.inputVencimento2{
  width: 140px !important;
  margin: 5px 0px !important;
}


.inputFormPagamento{
  width: 230px !important;
  margin: 5px 0px !important;
}

.inputDocumento{
  width: 300px !important;
  margin: 5px 0px !important;
}

.inputConta{
  width: 300px !important;
  margin: 5px 0px !important;
}

.scroll{
  overflow: auto !important;
  width: 100%;
}

.card-financeiro{
  // height: 200px;
  border-radius: 10px;
  border: 1px solid #afafaf;
  padding: 5px !important;
  
  p{
    font-size: 14px;
    margin: 0px;
  }

  button{
    padding: 0 !important;
  }
}