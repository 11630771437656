.card_extrato{
  border: 1px solid #DCDCDC;
  padding: 5px 50px 10px 10px;
  min-height: 160px;
  background-color: #DCDCDC;
  color: #000;
  position: relative;

  p{
    margin: 0;
    margin-top: 5px;
  }

  .button_selecionar{
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.card_extrato_green{
  border: 1px solid #3d852d;
  padding: 5px 10px 10px 10px;
  min-height: 160px;
  background-color: #3d852d;
  color: #FFF;
  position: relative;

  p{
    margin: 0;
    margin-top: 5px;
  }

  .button_selecionar{
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.card_extrato_red{
  border: 1px solid #FFF;
  padding: 5px 5px 10px 50px;
  min-height: 160px;
  background-color: #FFF;
  color: #000;
  position: relative;

  p{
    margin: 0;
    margin-top: 5px;
  }

  .button_selecionar{
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.card_extrato_yellow{
  border: 1px solid #FFFF00;
  padding: 5px 5px 10px 50px;
  min-height: 160px;
  background-color: #FFFF00;
  color: #000;
  position: relative;

  p{
    margin: 0;
    margin-top: 5px;
  }

  .button_selecionar{
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.movimentacoes{
  width: 100%;
  height: 450px;
  overflow: auto;
}

.logo_banco{
  width: 35px;
  position: absolute;
  left: 10px;
}

.dadosExtrato{
  background-color: #063e11;
  color: #FFF;
  border-radius: 10px;
  padding: 10px 10px;

  input, select{
    color: #FFF !important;
    -webkit-text-fill-color: #FFF !important;
    background-color: #063e11 !important
  }

  label{
    color: #FFF !important;
  }

  fieldset{
    border-color: #fff !important;
  }
}