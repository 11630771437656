.titulo{
    margin: 0;
}

.borderBottom{
  border-bottom: 1px solid #000;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btnCadastrar{
  margin-top: 10px !important;
  width: 100% !important;
}

h3{
  margin-bottom: 0;
}

h4{
  margin-bottom: 10px;
  margin-top: 5px;
}

.cardEndereco{
  border: 1px solid #c0c0c0;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.titulo{
    margin: 0;
}

.borderBottom{
    border-bottom: 1px solid #000;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.btnCadastrar{
    margin-top: 10px !important;
    width: 100% !important;
}


h3{
    margin-bottom: 0;
    margin-top: 5px;
}

h4{
    margin-bottom: 10px;
    margin-top: 5px;
}

.cardEndereco{
    border: 1px solid #c0c0c0;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 20px;
}


.outlined{
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 3px 10px !important;
  border-radius: 5px !important;
}

.outlined:hover{
  border: 1px solid rgba(0, 0, 0, 0.87) !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.cardSelectAulasOverFlow{
  width: 100%;
  height: 383px;
  overflow: auto;
}

.cursorPointer{
  cursor: pointer;
}

.aling-center{
  text-align: center !important;
}

.iconeTroca{
  color: #31353D;
  font-size: 3.5rem !important;
  margin-top: 160px;
}

#professorDisp.lista td p {
  margin: 3px;
  text-align: center;
  font-weight: 400;
  font-size: 14px !important;
  text-transform: capitalize;
  padding: 10px 0px;
}

#professorDisp.lista {
  border: 2px solid #ddd;
}

#professorDisp tbody {
  tr:first-child {
    border-bottom: 1px solid #ddd;
    border-top: none;
  }
}

.cursorpointer{
  cursor: pointer
}

.input-200w{
  max-width: 200px;
  min-width: 200px;
}

.btn_search_func {
  height: 40px !important;
  width: 100% !important;

  .MuiButton-endIcon {
    display: inherit;
    margin-left: 0px;
    margin-right: 0px;

    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 24px;
    }
  }
}

.cardApresentacao{
  width: 100%;
  height: 30px;
  padding: 2px 10px 0 10px;
  border-radius: 7px;
  margin-top: 7px;

  p{
    text-align: center;
  }
}

.alinha_botao{
  margin-left: calc(50% - 25px) !important;
}

.logo_selecionado{
  border: 2px solid #3f51b5;
  cursor: pointer;
}

.logo_nao_selecionada{
  cursor: pointer;
}

.logo_nao_selecionada:hover{
  border: 2px solid #3f51b5;
}

.icone_check{
  position: absolute !important;
  span {
    svg{
      font-size: 30px;
    }
  }
}

.icone_cancel{
  position: absolute !important;
  // margin-left: 40px !important;
  span {
    svg{
      font-size: 30px;
    }
  }
}

.excluir_img{
  color: red;
  text-align: right;
  font-size: 10px;
  cursor: pointer;
}

.visibilityPasswordCertificado{
  position: absolute;
  cursor: pointer;

  margin-top: 7px;
  margin-left: -35px;
}