.mg_top_5{
    margin-top: 5px !important;
}

.mg_top_10{
    margin-top: 10px !important;
}
.mg_top_20{
    margin-top: 20px !important;
}
.mg_top_30{
    margin-top: 30px !important;
}

.mg_bottom_10{
    margin-bottom: 10px !important;
}

.mg_bottom_20{
    margin-bottom: 20px !important;
}

.mg_left_10{
    margin-left: 10px !important;
}

.aling-center{
    text-align: center !important;
}

.cardSelectAulas{
	width: 100%;
	height: 435px;
	border: 2px solid#696969;
	overflow: hidden;

	h4{
		text-align: center;
		border-bottom: 2px solid#696969;
		color:#FFF;
		background-color: #063e11 !important;
		text-transform: uppercase !important;
		margin-bottom: 8px;
		margin-right: -1px;
		margin-top: -2px;
		font-size: 20px;
		margin-left: -1px;
		height: 40px;
		padding-top: 7px;
	}


	.fundo{
		background-color: #A9A9A9 !important;
		border-bottom: 2px solid#696969;
		height: 25px;
	}

	h5{
		text-align: center;
		color:#FFF;
		text-transform: uppercase !important;
		font-size: 14px;
		margin-top: -10px;
		margin-bottom: 5px;
	}

	.item{
		border-bottom: 1px solid #d0d0d0;
		padding: 5px 0;

		p{
			margin: 0px;
			padding: 5px 10px;
			font-size: 16px;
			float: left
		}
		
		.porcentagem{
			text-align: left;
			font-size: 22px;
			padding: 5px 0 0 0;
		}

		.input{
			float: left;
			input{
				text-align: right !important;
				font-size: 16px !important;
			}
		}
	}
	
	.cardSelectAulasOverFlow .item:hover{
		cursor: pointer;
		background-color:#063e11 !important;
		color: #FFF;
		font-size: 16px;
	}
}

.MuiSlider-markLabel{
	font-size: 10px !important;
}