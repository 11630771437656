.card_data{
  border: 1px solid #063e11;
  width: 60px;
  height: 40px;
  padding-top: 5px;
  text-align: center;
  float: left;
  margin-left: 5px;
  background-color: #063e11;
  color: #FFF;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold !important;

  span{
    font-size: 14px;
  }
}

.card_seta{
  background-color: #FFF;
  color: #000;
  width: 40px;
  height: 40px;
  padding-top: 5px;
  padding-left: 5px;
  text-align: center;
  float: left;
  margin-left: 5px;
  border-radius: 3px;
  font-weight: bold !important;
  cursor: pointer;
}

.card_data.selecionado{
  background-color: #FFF;
  color: #000;
}

.centraliza{
  width: 590px;
  margin-left: calc(50% - 295px)
}

.table_agenda{
  border: 2px solid #063e11;
  // width: 100%;
  border-spacing: 0;

  tr{
    // th:first-child{
    //   text-align: right;
    //   // width: 70px;
    // }

    th{
      // font-size: 18px !important;
      border: 1px solid #FFF;
      text-align: center;
      background-color: #063e11;
      color: #FFF;
    }

    // td:first-child{
    //   text-align: right,
    // }

    td{
      border: 1px solid #063e11;
      vertical-align:top
    }
  }

}

.card_agenda{
  margin-left: 5%;
  // border-radius: 3px;
  padding: 10px;
  text-align: center;
  cursor: pointer;

  // box-shadow: 5px 5px 5px black;
  box-shadow:0px 0px 0px 5px #FFF inset;


  p{
    margin: 0px;
  }
}

.rolagem {
  height: 85vh;
  // width: 3200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.icone_warning{
  color: red;
  font-size: 45px !important;
}

.situacao{
  padding: 5px;
  border-radius: 7px;
}

.rolagen_horizontal{
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  // white-space:nowrap;
}

.grid-container {
  display: grid; /* This is a (hacky) way to make the .grid element size to fit its content */
  overflow: auto;
  height: 600px;
  width: 100%;
  border: 1px solid #C0C0C0;
}

.grid {
  display: flex;
  flex-wrap: nowrap;
}

.grid-col {
  // width: 150px;
  // min-width: 150px;
}

.grid-item-header {
  height: 20px;
  min-height: 20px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  text-align: center;
  background-color: #063e11;
  color: #FFF;
  z-index: 999;
}

.grid-col--fixed-left {
  position: sticky;
  left: 0;
  // z-index: 9998;
  background: white;
}

.grid-col--fixed-right {
  position: sticky;
  right: 0;
  // z-index: 9998;
  background: white;
}

.grid-item {
  height: 30px;
  text-align: center;
  border: 1px solid #C0C0C0;
  // border: 1px solid gray;
}

.card_recorrencia{
  border: 2px solid #C0C0C0;
  border-radius: 10px;
  padding: 10px;
}

.card_aviso_voucher{
  width: 100%;
  background-color: red;
  color: #FFF;
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;

  span{
    position: relative;
    top: 3px;
    left: 5px;
  }
}