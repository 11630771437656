// @media (min-width: 701px) {
// 	.img_logo{
// 		left: 50%;
// 		top: 80px;
// 		position: absolute;
// 		transform: translateX(-50%);
// 		width:	30%;
// 	}
// }

// @media (max-width: 700px) {
// 	.img_logo{
// 		left: 50%;
//     top: 190px;
//     position: absolute;
//     transform: translateX(-50%);
//     width: 90%;
// 	}
// }

// .container_logo{
// 	position: relative;
// 	width:100%;
// }	

.img_logo{
	width: 100%;
}

.card_rel{
	
	.div_chart{
		z-index: 50;
		background-color: transparent !important;
		border-radius: 10px;
		border: 1px solid #063e11 !important;
		width: 100%;
		font-weight: bold;
		
		.valor_totais{
			width: 100%;
			height: 100%;
			padding-top: 30px;
			padding-bottom: 10px;
			text-align: center;

			span{
				font-size: 1.3em;
			}
		}
		
		p{
			margin-top: 3px !important;
			height: 20%;
			font-size: 0.8em;
			color: #063e11;
		}

		.chart{
			overflow: auto;
			height: 80%;
		}
	}
}

.row_click{
	cursor: pointer;
}

.row_click:hover{
	background-color: #063e11;
	color: #fff;
}

.row_chart{
	padding: 10px;

	.MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 2px;
	}

	.bar{
		font-size: 0.8em;
	}

	.descricao{
		text-align: right;
		margin-top: 6px;
		font-size: 0.8em;
	}
}

.bar_totais{
	height:	60px;
	float: left;
	text-align: center;
	font-size: 18px;
	padding-top: 18px;
}

.tela_home{
	// background-color: #063e11;
}

g{
	text: {
		font-size: 12px !important;
	}
}

.info{
	cursor: pointer;
	color: #063e11;
	font-size: 24px !important;
}

.div_entrada_saida{
	height: 24vh;
}

.iconeTroca{
  color: #063e11;
  font-size: 3.5rem !important;
  margin-top: 160px;
}

@media (max-width: 960px) {
	.div_result{
		height: 100px;
	}

	.div_entrada_saida{
		height: 140px;
	}

	// .logo_mais_financeiro{
	// 	width: 30% !important;
	// 	margin-left: 35% !important;
	// }
}

@media (max-width: 700px) {
	.div_result{
		height: 100px;
	}

	.div_entrada_saida{
		height: 140px;
	}
}