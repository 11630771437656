h5{
  margin: 5px;
}

.cardPlano:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.cardPlano:last-child{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cardPlano{
  width: 100%;
  height: 400px;
  border: 1px solid #D3D3D3;
  overflow: hidden;
  margin-top: 5px !important;

  .titulo{
    background-color:#DCDCDC;
    color: #000;
    // text-align: center;
    height: 40px;
    padding-top: 10px;
    padding-left: 20px;

    button{
      margin-top: -15px;
      span{
        svg{
          font-size: 25px;
        }
      }
    }
  }

  .conteudo{
    height: 360px;
    overflow: auto;
    .linha_plano{
      cursor: pointer;
      padding: 5px 0px;
  
      .plano_codigo{
        text-align: center;
        span{
          font-size: 12px;
        }
      }
    
      .plano_descricao{
        span{
          font-size: 12px;
        }
      }
    
      .plano_niveis_inferior{
        text-align: center;
        span{
          font-size: 12px;
        }
      }
    }
  
    .linha_plano:hover{
      background-color: #4F4F4F;
      .plano_codigo{
        span{
          color: #FFF;
        }
      }
    
      .plano_descricao{
        span{
          color: #FFF;
        }
      }
  
      .plano_niveis_inferior{
        div{
          width: 20px;
          height: 20px;
          margin-left: calc(50% - 10px);
          margin-top: 4px;
          border-radius: 50%;
          background-color: #FFF;
          span{
            color: #063e11;
            position: relative;
            top: -4px;
          }
        }
      }
    }
  
    .linha_plano.active{
      background-color: #063e11;
      .plano_codigo{
        span{
          color: #FFF;
        }
      }
    
      .plano_descricao{
        span{
          color: #FFF;
        }
      }
  
      .plano_niveis_inferior{
        div{
          width: 20px;
          height: 20px;
          margin-left: calc(50% - 10px);
          margin-top: 4px;
          border-radius: 50%;
          background-color: #FFF;
          span{
            color: #063e11;
            position: relative;
            top: -4px;
          }
        }
      }
    }
  }

  .sem_plano{
    padding: 5px 10px;
    span{
      font-size: 12px;
    }
  }
  

}

