.container-boletos{
  width: 100%;
  background-color: #FFF !important;
  padding: 20px;
}

.container_logo_cliente{
  position: fixed;
  width: 100%;
}

.icone_nfse{
  width: 28px !important;
  margin-left: calc(50% - 14px);
}