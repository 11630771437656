#perfil.cadastro {
  width: 100%;

  h3 {
    display: none;
  }

  linhas {
    height: 40px;
  }

  .permissoes {
    .descricao {
      padding-left: 10px;
      position: relative;
      top: 13px;
    }

    .switch {
      margin-top: 5px;
      margin-left: 25px;
    }
  }

  .btn_salvar {
    margin-top: 20px;
  }

  .lista_permissoes{
    padding: 10px;
    height: 500px;
    overflow: auto;
  }

  .closeCard {
    height: 40px;
    overflow: hidden;
  }

  .card_grupo{ 
    width: 100%;
    border: 1px solid #a1a0a0;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 5px;
    border-radius: 5px;
    position: relative;

    h6{
      margin-bottom: 10px;
    }

    .button{
      position: absolute;
      right: -5px;

      span svg {
        font-size: 35px;
      }
    }
  }

  .card_grupo:first-child{
    margin-top: 5px;
  }
}