.card_cadastro{
	border: 1px solid #C0C0C0;
	border-radius: 15px;
	text-align: center;

	img{
		width: 60%;
		margin-left: 20%;
	}
}

.card_dados{
	border: 1px solid #d0d0d0;
	border-radius: 10px;
	padding: 10px !important;
}