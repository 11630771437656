.wrapper-admin {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  background: rgba(darken(#00008B,50%), 0.8);
  // background-color: #417e1f;
}

.input-admin {
  display: block;
  padding: 15px 10px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #ddd;
  transition: border-width 0.2s ease;
  border-radius: 2px;
  color: #000;
  
  &+ i.fa {
    color: #fff;
    font-size: 1em;
    position: absolute;
    margin-top: -47px;
    opacity: 0;
    left: 0;
    transition: all 0.1s ease-in;
  }
  
  &:focus {
    &+ i.fa {
      opacity: 1;
      left: 35px;
      transition: all 0.25s ease-out;
    }
    outline: none;
    color: #000;
    border-color: #00008B !important;
    border-left-width: 45px;
  }
}

.card_aplicacao{
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  p{
    margin: 0;
  }

  .online{
    background-color: green;
    text-align: center;
    color: #fff;
    border-radius: 10px;
  }

  .offline{
    background-color: red;
    text-align: center;
    color: #fff;
    border-radius: 10px;
  }
}

.card_aplicacao:hover{
  border: 3px solid #00008B;
}
