@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600;700&display=swap');

/* Container do texto "Feliz natal" */
.text {
  position: absolute;
  bottom: 10px;
  right: 170px;
  text-align: center;
  animation: pop 1s ease 2.5s backwards;
  -webkit-animation: pop 1s ease 2.5s backwards;
}

/* Texto de feliz natal */
.merry {
  font-family: 'Dancing Script', cursive;
  color: #fff;
  font-size: 2rem;
}

.merry--0 {
  animation: appear 0.5s ease 1s backwards;
  -webkit-animation: appear 0.5s ease 1s backwards;
}
.merry--1 {
  animation: appear 0.5s ease 1.5s backwards;
  -webkit-animation: appear 0.5s ease 1.5s backwards;
}
.merry--2 {
  animation: appear 0.5s ease 2s backwards;
  -webkit-animation: appear 0.5s ease 2s backwards;
}

.merry--3 {
  animation: appear 0.5s ease 2.5s backwards;
  -webkit-animation: appear 0.5s ease 2.5s backwards;
}

/* Container da árvore de natal */
.tree {
  height: 215px;
  bottom: 20px;
  width: 163px;
  right: 0px;
  position: absolute;
}

/* A estrela no topo da árvore */
.star {
  width: 2rem;
  height: 2rem;
  right: -26.5px;
  top: 0px;
  clip-path: polygon(
    49% 0,
    61% 30%,
    100% 35%,
    71% 57%,
    80% 90%,
    47% 67%,
    20% 90%,
    30% 56%,
    1% 36%,
    37% 30%
  );
  background-color: #f7c600;
  filter: drop-shadow(0 0.1rem 0.3rem rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0 0.1rem 0.3rem rgba(0, 0, 0, 0.9));
  position: relative;
  z-index: 100;
}

/* Os triangulos dentro da estrela */
.mini__star {
  position: absolute;
}

.mini__star--1 {
  width: 0.5rem;
  height: 0.9rem;
  top: 0.05rem;
  left: 51%;
  clip-path: polygon(38% 0, 0 70%, 40% 100%);
  transform: translateX(-44%);
  background-color: #ffea00;
  -webkit-transform: translateX(-44%);
  -moz-transform: translateX(-44%);
  -ms-transform: translateX(-44%);
  -o-transform: translateX(-44%);
}

.mini__star--2 {
  width: 1.4rem;
  height: 0.54rem;
  top: 0.93rem;
  right: 0.1rem;
  background-color: #ffea00;
  clip-path: polygon(48% 0, 40% 61%, 100% 15%);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.mini__star--3 {
  background-color: #ffea00;
  width: 0.8rem;
  height: 0.9rem;
  clip-path: polygon(80% 10%, 38% 0, 100% 65%);
  bottom: -0rem;
  right: 0.46rem;
}

/* Triangulos que compõem a árvore de natal */
.triangle__1 {
  clip-path: polygon(0 71%, 48% 0, 100% 71%, 67% 73%, 49% 100%, 28% 71%);
  width: 2.5rem;
  height: 2.5rem;
  background-color: #5bcf64;
  top: -15px;
  right: -22px;
  position: relative;
  z-index: 10;
}

.shadow__1 {
  clip-path: polygon(0 71%, 48% 0, 100% 71%, 67% 73%, 49% 100%, 28% 71%);
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba(10, 117, 99, 0.876);
  top: -148px;
  right: -22px;
  transform: translateY(5rem);
  -webkit-transform: translateY(5rem);
  -moz-transform: translateY(5rem);
  -ms-transform: translateY(5rem);
  -o-transform: translateY(5rem);
  position: relative;
  z-index: 9;
}

.triangle__2 {
  clip-path: polygon(0 71%, 48% 0, 100% 71%, 67% 73%, 49% 100%, 28% 71%);
  width: 3.75rem;
  height: 3.75rem;
  background-color: #00af00;
  top: -85px;
  right: 22px;
  position: relative;
  z-index: 8;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
}

.shadow__2 {
  clip-path: polygon(0 71%, 48% 0, 100% 71%, 67% 73%, 49% 100%, 28% 71%);
  width: 3.75rem;
  height: 3.75rem;
  background-color: rgba(10, 117, 99, 0.876);
  top: -150px;
  right: 22px;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  position: relative;
  z-index: 7;
}

.triangle__3 {
  clip-path: polygon(0 71%, 48% 0, 100% 71%, 67% 73%, 49% 100%, 28% 71%);
  width: 5rem;
  height: 3.75rem;
  background-color: #5bcf64;
  top: -188px;
  right: 44px;
  position: relative;
  z-index: 6;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
}

.shadow__3 {
  clip-path: polygon(0 71%, 48% 0, 100% 71%, 67% 73%, 49% 100%, 28% 71%);
  width: 5rem;
  height: 3.75rem;
  background-color: rgba(9, 34, 30, 0.876);
  top: -252px;
  right: 44px;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  position: relative;
  z-index: 5;
}

.triangle__4 {
  clip-path: polygon(0 71%, 48% 0, 100% 71%, 67% 73%, 49% 100%, 28% 71%);
  width: 6.25rem;
  height: 3.75rem;
  background-color: #00af00;
  top: -292px;
  right: 66px;
  position: relative;
  z-index: 4;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
}

/* Caule da árvore */
.post__container {
  position: absolute;
  top: 0px;
  right: 60px;
  z-index: 1;
}

.post__1 {
  position: relative;
  top: 75px;
  right: -72px;
  height: 2.5rem;
  width: 0.25rem;
  background-color: #9b4f2c;
  border-radius: 0 0 0 5rem;
  -webkit-border-radius: 0 0 0 5rem;
  -moz-border-radius: 0 0 0 5rem;
  -ms-border-radius: 0 0 0 5rem;
  -o-border-radius: 0 0 0 5rem;
}
.post__2 {
  position: relative;
  top: 30px;
  right: -76px;
  height: 2.5rem;
  width: 0.25rem;
  background-color: #c78100;
  border-radius: 0 0 5rem 0;
  -webkit-border-radius: 0 0 5rem 0;
  -moz-border-radius: 0 0 5rem 0;
  -ms-border-radius: 0 0 5rem 0;
  -o-border-radius: 0 0 5rem 0;
}

.post__shad {
  position: relative;
  top: 140px;
  right: -35px;
  width: 7.4rem;
  height: 5rem;
  border-radius: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  -ms-border-radius: 3rem;
  -o-border-radius: 3rem;
  background-color: rgba(10, 117, 99, 0.411);
  clip-path: polygon(100% 0, 0 0, 51% 100%);
  transform: translateX(-21%);
  -webkit-transform: translateX(-21%);
  -moz-transform: translateX(-21%);
  -ms-transform: translateX(-21%);
  -o-transform: translateX(-21%);
}

/* Recipiente da árvore */
.ball {
  position: absolute;
  bottom: 0rem;
  width: 14rem;
  height: 6rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  z-index: -20;
  transform: translateX(-30%);
  -webkit-transform: translateX(-30%);
  -moz-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  -o-transform: translateX(-30%);
}

/* Enfeites do corpo da árvore */
.sticker {
  width: 0.5rem;
  height: 0.2rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #19381c2c;
  position: absolute;
  left: 0.5rem;
  top: 1.2rem;
}

.sticker__4 {
  width: 1rem;
  height: 0.4rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #19381c2c;
  position: absolute;
  left: 1rem;
  top: 2rem;
}
.sticker__5 {
  width: 1rem;
  height: 0.4rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #19381c2c;
  position: absolute;
  left: 4rem;
  top: 1.6rem;
}

.sticker__6 {
  width: 4rem;
  height: 0.5rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #19381c2c;
  position: absolute;
  left: 5rem;
  top: 7rem;
}

.sticker__7 {
  width: 0.5rem;
  height: 0.2rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #19381c2c;
  position: absolute;
  left: 0.9rem;
  top: 1.85rem;
}

.sticker__8 {
  width: 1rem;
  height: 0.4rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #19381c2c;
  position: absolute;
  left: 1.4rem;
  top: 1.8rem;
}

.sticker__9 {
  width: 0.5rem;
  height: 0.2rem;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #3c523e2c;
  position: absolute;
  left: 2.3rem;
  top: 1.7rem;
}

/* Bolas coloridas na árvore */
.light {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
}

.light--1 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 60%;
  left: 15%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--2 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 70%;
  right: 43%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--3 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 60%;
  right: 5%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--4 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 60%;
  left: 15%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--5 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 75%;
  right: 46%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--6 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 60%;
  right: 5%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--7 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 58%;
  right: 78%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--8 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 68%;
  right: 58%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--9 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 68%;
  right: 35%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

.light--10 {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
  background-color: #ffffff;
  position: absolute;
  top: 58%;
  right: 13%;
  transform: translate(-50%, -30%);
  -webkit-transform: translate(-50%, -30%);
  -moz-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  -o-transform: translate(-50%, -30%);
  z-index: 100;
}

/* Caixas de presentes */
.boxes {
  position: absolute;
  bottom: 85px;
  right: 135px;
  width: max-content;
  display: flex;
  z-index: 11;
}

.box {
  position: relative;
}
.box--1 {
  position: relative;
  margin-left: 0rem;
}

.box--2 {
  position: absolute;
  top: 1rem;
  left: 2rem;
}

.box--3 {
  position: absolute;
  margin-left: 5rem;
  top: 0rem;
  left: -1rem;
}

/* corpo da caixa */
.body {
  width: 1.8rem;
  height: 1rem;
  top: 3rem;
  left: 0rem;
  clip-path: polygon(0 0, 100% 0, 93% 100%, 7% 99%);
  position: relative;
  background-color: #fff;
}

/* Tampa da caixa */
.cover {
  width: 2rem;
  height: 0.5rem;
  position: relative;
  background-color: #fff;
  top: 3rem;
  left: 1.4rem;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  transform: translateX(-1.5rem);
  -webkit-transform: translateX(-1.5rem);
  -moz-transform: translateX(-1.5rem);
  -ms-transform: translateX(-1.5rem);
  -o-transform: translateX(-1.5rem);
}

.body::before,
.cover::before,
.cover::after,
.body::after {
  position: absolute;
  content: ' ';
  top: 0;
  width: 35%;
  height: 100%;
}

.body::before {
  left: 0;
  background-color: #7e470b;
}
.body::after {
  right: 0;
  background-color: #7e470b;
}

.body--1::before {
  left: 0;
  background-color: #b93a14;
}

.body--1::after {
  right: 0;
  background-color: #b93a14;
}

.body--2::before {
  left: 0;
  background-color: #1492b9;
}

.body--2::after {
  right: 0;
  background-color: #1492b9;
}

.cover::before {
  background-color: #ffd700;
  left: 0;
}

.cover::after {
  background-color: #ffd700;
  right: 0;
}

.cover--1::before {
  background-color: #ffa600;
  left: 0;
}

.cover--1::after {
  background-color: #ffa600;
  right: 0;
}

.cover--2::before {
  background-color: #56cef3;
  left: 0;
}

.cover--2::after {
  background-color: #56cef3;
  right: 0;
}

/* Prato da caixa */
.plate {
  position: absolute;
  top: 4.1rem;
  left: -0.3rem;
  width: 2rem;
  height: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

/* Pega da caixa */
.hold {
  width: 5rem;
  height: 0.5rem;
  border-radius: 5rem 5rem 0 0;
  -webkit-border-radius: 5rem 5rem 0 0;
  -moz-border-radius: 5rem 5rem 0 0;
  -ms-border-radius: 5rem 5rem 0 0;
  -o-border-radius: 5rem 5rem 0 0;
  border: 3px solid #ffd700;
  position: absolute;
  top: -0.5rem;
  left: 40%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.hold--1 {
  border: 3px solid #ffa600;
}

.hold--2 {
  border: 3px solid #56cef3;
}

/* Animação das caixas */
.anime {
  animation: light 1s ease backwards infinite;
  -webkit-animation: light 1s ease backwards infinite;
}

.light--1 {
  animation: light 1s ease 0.2s backwards infinite;
  -webkit-animation: light 1s ease 0.2s backwards infinite;
}

.light--2 {
  animation: light 1s ease 0.4s backwards infinite;
  -webkit-animation: light 1s ease 0.4s backwards infinite;
}

.light--3 {
  animation: light 1s ease 0.6s backwards infinite;
  -webkit-animation: light 1s ease 0.6s backwards infinite;
}

.light--4 {
  animation: light 1s ease 0.8s backwards infinite;
  -webkit-animation: light 1s ease 0.8s backwards infinite;
}

.light--5 {
  animation: light 1s ease 1s backwards infinite;
  -webkit-animation: light 1s ease 1s backwards infinite;
}

.light--6 {
  animation: light 1s ease 1.2s backwards infinite;
  -webkit-animation: light 1s ease 1.2s backwards infinite;
}

.light--7 {
  animation: light 1s ease 1.4s backwards infinite;
  -webkit-animation: light 1s ease 1.4s backwards infinite;
}

.light--8 {
  animation: light 1s ease 1.6s backwards infinite;
  -webkit-animation: light 1s ease 1.6s backwards infinite;
}

.light--9 {
  animation: light 1s ease 1.8s backwards infinite;
  -webkit-animation: light 1s ease 1.8s backwards infinite;
}

.light--10 {
  animation: light 1s ease 2s backwards infinite;
  -webkit-animation: light 1s ease 2s backwards infinite;
}


@keyframes light {
  0% {
    background-color: #ffa600;
  }
  25% {
    background-color: #56cef3;
  }
  50% {
    background-color: #b93a14;
  }
  75% {
    background-color: #fff;
  }
  100% {
    background-color: #8314b9;
  }
}

/* Animação do texto */
@keyframes appear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
  }
}

/* Animação das caixas */
@keyframes movein {
  0% {
    opacity: 0;
    transform: translateY(2.5rem);
    -webkit-transform: translateY(2.5rem);
    -moz-transform: translateY(2.5rem);
    -ms-transform: translateY(2.5rem);
    -o-transform: translateY(2.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

.animate--1 {
  animation: movein 0.2s ease-in-out 1s backwards;
  -webkit-animation: movein 0.2s ease-in-out 1s backwards;
}
.animate--2 {
  animation: movein 0.2s ease-in-out 1.3s backwards;
  -webkit-animation: movein 0.2s ease-in-out 1.3s backwards;
}
.animate--3 {
  animation: movein 0.2s ease-in-out 1.1s backwards;
  -webkit-animation: movein 0.2s ease-in-out 1.1s backwards;
}