@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
  
@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

body {
  font-size: 0.9rem;
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
  
/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.toggled .sidebar-wrapper {
  left: 0px !important;
}

@media screen and (min-width: 768px) {
  .toggled .page-content {
    padding-left: 300px !important;
  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 5px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper-close {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
}

@media (min-width: 701px) {
  .sidebar-wrapper-open {
    width: 20%;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
  
    -webkit-animation: sidebar-wrapper-open 0.3s normal forwards ease-in-out;
             -moz-animation: sidebar-wrapper-open 0.3s normal forwards ease-in-out;
                  animation: sidebar-wrapper-open 0.3s normal forwards ease-in-out;
          -webkit-transform-origin: 0% 100%;
             -moz-transform-origin: 0% 100%;
                  transform-origin: 0% 100%;
  }
}

@media (max-width: 700px) {
  .sidebar-wrapper-open {
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
  
    -webkit-animation: sidebar-wrapper-open 0.3s normal forwards ease-in-out;
             -moz-animation: sidebar-wrapper-open 0.3s normal forwards ease-in-out;
                  animation: sidebar-wrapper-open 0.3s normal forwards ease-in-out;
          -webkit-transform-origin: 0% 100%;
             -moz-transform-origin: 0% 100%;
                  transform-origin: 0% 100%;
  }
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
  color: #CCC
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 10px 20px 10px 20px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.btn-dark{
  background-color: #063e11 !important;
  border-color: #063e11 !important;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 5px 30px 5px 20px;
  cursor: pointer;
  font-weight: bold;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "FontAwesome";
  font-weight: 900;
  content: '\f105';
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 8px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 15px;
  font-size: 16px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "FontAwesome";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu.close {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu.open {
  display: block;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
}

.sidebar-wrapper-close .sidebar-footer{
  display: none !important;
}

.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
}

.badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 20px;
}

.page-wrapper .page-content > div {
  padding: 20px 40px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.sidebar-wrapper {
  background-color: #063e11 !important;
  z-index: 1000;
}

.sidebar-wrapper .sidebar-header,
.sidebar-wrapper .sidebar-search,
.sidebar-wrapper .sidebar-menu {
    border-top: 1px solid #3d852d;
}

.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.sidebar-wrapper .sidebar-header .user-info .user-role,
.sidebar-wrapper .sidebar-header .user-info .user-status,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-brand>a,
.sidebar-wrapper .sidebar-menu ul li a,
.sidebar-footer>a {
    color: #FFF;
}

.sidebar-wrapper .sidebar-menu ul li:hover>a,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.sidebar-wrapper .sidebar-header .user-info,
.sidebar-wrapper .sidebar-brand>a:hover,
.sidebar-footer>a:hover i {
    color: #7cb453;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}

.sidebar-wrapper ul li:hover a i,
.sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #063e11;
    text-shadow:0px 0px 10px #063e11;
}

.sidebar-wrapper .sidebar-menu ul li a i,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text {
    background: #3d852d;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}

.sidebar-footer {
    background: #3d852d;
    box-shadow: 0px -1px 5px #063e11;
    border-top: 1px solid #7cb453;
}

.sidebar-footer>a:first-child {
    border-left: none;
}

.sidebar-footer>a:last-child {
    border-right: none;
}

.card_swap_closed{
  width: 25px;
  height: 25px;
  background-color: #063e11;
  position: fixed;
  top: 5px;
  right: 5px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;

  i{
    color: #FFF;
    font-size: 14px;
  }
}

.card_swap_closed:hover{
  background-color: rgb(58, 63, 72);
}

.unidadeSelecionada.open{
  display: none;
}

@-webkit-keyframes card_swap_open {
  from { transform: scaleY(0) }
  to { transform: scaleY(1) }
}
@-moz-keyframes card_swap_open {
  from { transform: scaleY(0) }
  to { transform: scaleY(1) }
}
@keyframes card_swap_open {
  from { transform: scaleY(0) }
  to { transform: scaleY(1) }
}


@-webkit-keyframes sidebar-wrapper-open {
  from { transform: scaleX(0) }
  to { transform: scaleX(1) }
}
@-moz-keyframes sidebar-wrapper-open {
  from { transform: scaleX(0) }
  to { transform: scaleX(1) }
}
@keyframes sidebar-wrapper-open {
  from { transform: scaleX(0) }
  to { transform: scaleX(1) }
}

.user-name{
  font-size: 12px;
}

.sidebar-brand{
  font-size: 12px;
}

.sidebar-menu{
  font-size: 12px;
  text-transform: uppercase
}

.sidebar-submenu a{
  font-size: 12px;
}

.MuiFormControlLabel-root{
  margin-left: 0px !important;
}

.notificacao{
  position: fixed;
  top: -5px;
  right: 385px;
  z-index: 998;

  svg{
    font-size: 35px;
    color: '#FFD700' !important
  }
}

.num_notificacao{
  position: fixed;
  top: 22px;
  right: 392px;
  width: 17px;
  height: 17px;
  background-color: red;
  color: #FFF;
  z-index: 999;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;

  span{
    font-weight: 600;
    font-size: 14px;
    top: -4px;
    position: relative;
  }
}

.itemNotificacao{
  font-size: 12px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
}

.icone_close{
  margin-top: -5px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
}

.center{
  text-align: center;
}

.caixa{
  // position: absolute;
  // top: 10px;
  // right: 420px;

  p{
    margin: 0px;
    color: green;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.logo_mais_financeiro{
  width: 50%;
  margin-left: 25%;
}

.wifi{
  position: fixed;
  top: 7px;
  right: 440px;

  img{
    width: 35px;
  }
}

@media (max-width: 700px) {
  .card_swap_open{
    background-color: #063e11 !important;
    width: 100%;
    height: 100%;
    top:0;
    position: fixed;
    z-index: 999;
    color: #FFF;
  
    -webkit-animation: card_swap_open 0.5s normal forwards ease-in-out;
             -moz-animation: card_swap_open 0.5s normal forwards ease-in-out;
                  animation: card_swap_open 0.5s normal forwards ease-in-out;
          -webkit-transform-origin: 100% 0%;
             -moz-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
  
    p{
      margin: 0;
    }
  
    .topo{
      height: 90px;
      min-height: 90px;
      max-height: 90px;
  
      .center{
        text-align: center;
      }
    
      i{
        font-size: 25px;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
    
      h5{
        font-size: 16px;
        text-transform: uppercase;
        position: relative;
        top: 5px;
      }
    }
  
    .meio{
      height: calc(90% - 100px);
      min-height:calc(90% - 100px);
      max-height:calc(90% - 100px);
      overflow: auto;
  
      svg{
        color: #FFF;
      }
    
      .renderUnidades{
        margin-left: 10px;
        margin-top: 10px;
  
        span{
          font-size: 14px;
          margin-left: 5px;
        }
      }
    
      .subTitulo{
        margin-left: 10px;
        span{
          font-size: 14px;
        }
      }
    
      .franquias{
        margin-left: 10px;
        span{
          font-size: 14px;
        }
      }
  
      .unidades{
        margin-left: 10px;
        span{
          font-size: 14px;
        }
      }
    
      .subSubTitulo{
        margin-left: 10px;
        span{
          font-size: 14px;
        }
      }
    }
  
    .base{
      height: 10%;
      min-height: 10%;
      max-height: 10%;
  
      position: relative;
      bottom: 0;
    }
  }

  .unidadeSelecionada.closed{
    width: 200px;
    height: 25px;
    background-color: #063e11;
    position: fixed;
    top: 5px;
    right: 35px;
    border-radius: 10px;
    padding: 3px 10px;
    color: #FFF;
    overflow: hidden;
    
    p{
      font-size: 12px;
    }
  }

  .notificacao{
    position: fixed;
    top: -5px;
    right: 225px;
    z-index: 998;
  
    svg{
      font-size: 35px;
    }
  }

  .num_notificacao{
    position: fixed;
    top: 20px;
    right: 240px;
    width: 15px;
    height: 15px;
    background-color: red;
    color: #FFF;
    z-index: 999;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    
    span{
      font-weight: 600;
      font-size: 12px;
      top: -6px;
      position: relative;
    }
  }

  .logo_mais_financeiro{
    width: 40%;
    margin-left: 30%;
  }

  .wifi{
    position: fixed;
    top: 7px;
    right: 275px;
  
    img{
      width: 35px;
    }
  }
}

@media (min-width: 701px) {
  .card_swap_open{
    background-color: #063e11 !important;
    width: 350px;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 999;
    color: #FFF;
  
    -webkit-animation: card_swap_open 0.5s normal forwards ease-in-out;
             -moz-animation: card_swap_open 0.5s normal forwards ease-in-out;
                  animation: card_swap_open 0.5s normal forwards ease-in-out;
          -webkit-transform-origin: 100% 0%;
             -moz-transform-origin: 100% 0%;
                  transform-origin: 100% 0%;
  
    p{
      margin: 0;
    }
  
    .topo{
      height: 40px;
      min-height: 40px;
      max-height: 40px;
  
      .center{
        text-align: center;
      }
    
      i{
        font-size: 25px;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
    
      h5{
        font-size: 16px;
        text-transform: uppercase;
        position: relative;
        top: 5px;
      }
    }
  
    .meio{
      height: calc(92% - 40px);
      min-height:calc(92% - 40px);
      max-height:calc(92% - 40px);
      overflow: auto;
      max-width: 350px;
        
      .renderUnidades{
        margin-bottom: 5px;
        // margin-top: 10px;
        cursor: pointer;

        svg{
          color: #FFF;
          font-size: 20px;
          margin-left: 10px;
        }
        
        span{
          margin-left: 5px;
          font-size: 14px;
          text-transform: uppercase;
          // font-weight: bold;
        }
      }
    
      .representante{
        margin-top: 5px;
      }

      .subTitulo{
        margin-left: 10px;
        span{
          font-size: 12px;
        }
      }
    
      .franquias{
        margin-left: 20px;
        position: relative;

        .iconeMinimize{
          position:absolute;
          right: 3px;
          top: 2px;
        }
      }

      .franquiasFechada {
        margin-left: 20px;
        position: relative;
        max-height: 27px;
        overflow: hidden ;

        span{
          font-size: 14px;
        }

        .iconeMaximize{
          position:absolute;
          right: 3px;
          top: 2px;
        }
      }
  
      .unidades{
        margin-left: 20px;
        margin-top: 5px;
        span{
          font-size: 12px;
        }
      }
    
      .subSubTitulo{
        margin-left: 10px;
        span{
          font-size: 14px;
        }
      }
    }
  
    .base{
      height: 8%;
      min-height: 8%;
      max-height: 8%;
  
      position: relative;
      bottom: 0;
    }
  }

  .unidadeSelecionada.closed{
    width: 350px;
    height: 25px;
    background-color: #063e11;
    position: fixed;
    top: 5px;
    right: 35px;
    border-radius: 10px;
    padding: 3px 10px;
    color: #FFF;
    overflow: hidden;

    p{
      font-size: 12px;
      text-transform: uppercase
    }
  }
}